import React from 'react';
import styled from '@emotion/styled';
import { mq } from 'style/variables';
import sizes from 'style/sizes';
import { css } from '@emotion/core';

type ContentInnerProps = {
  size?: 'medium' | 'large';
  position?: 'left' | 'right';
  spPadding?: boolean;
};

const Container = styled.div`
  width: 100%;
`;

const Inner = styled.div<ContentInnerProps>`
  margin-left: ${props => (props.position === 'left' ? 0 : 'auto')};
  margin-right: ${props => (props.position === 'right' ? 0 : 'auto')};
  ${mq.onlypc} {
    max-width: ${props => (props.size === 'medium' ? 790 : props.size === 'large' ? 938 : 700)}px;
  }
  ${mq.onlysp} {
    ${props =>
      props.spPadding &&
      css`
        padding: 0 ${sizes.margin[32]};
      `}
  }
`;

export const ContentInner: React.FC<ContentInnerProps> = props => {
  return (
    <Container>
      <Inner {...props}>{props.children}</Inner>
    </Container>
  );
};
